import $ from 'jquery';

$(() => {
  const $cards = $('[data-card]');

  $cards.each(function () {
    const $card = $(this);
    const $svg = $(this).find('svg');
    $svg[0].pauseAnimations();

    $card.on('mouseenter', () => {
      $svg[0].unpauseAnimations();
    })

    $card.on('mouseleave', () => {
      $svg[0].pauseAnimations();
    })
  })
})