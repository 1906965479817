import $ from 'jquery';

$(() => {
  const $reveal = $('[data-component="reveal"]');

  $reveal.each(function () {
    const children = $reveal.children();
    $(this).addClass('animation-reveal');
    let revealer = document.createElement('div');
    revealer.classList.add('reveal-lr');
    this.appendChild(revealer); 
    
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(this).find('div').addClass('active');
        } else {
          $(this).find('div').removeClass('animation-flip-end');
        }
      });
    };
    const observer = new IntersectionObserver(callback, {});
    observer.observe(this);
  });
});
