import $ from 'jquery';

$(() => {
  const $scrollTop =  $('[data-scroll-top]');
  let scrollPosition = 0;
  if($scrollTop.length) {
    $(window).scroll(function(evt) {
      const currentScrollPosition = $(this).scrollTop();
      if(currentScrollPosition < scrollPosition && currentScrollPosition !== 0) {
        $scrollTop.addClass('scroll-top--is-visible');
      } else {
        $scrollTop.removeClass('scroll-top--is-visible');
      }
      scrollPosition = currentScrollPosition;
    })
  }
})