import $ from 'jquery';

$(() => {
  const $flip = $('[data-component="flip"]');

  $flip.each(function () {
    const $children = $flip.children().clone();
    $(this).append($children)
    $(this).addClass('animation-flip');

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(this).addClass('animation-flip-start')
          // setTimeout(() => {
          //   $(this).addClass('animation-flip-end');
          // }, 9200);
        } else {
          $flip.removeClass('animation-flip-start').removeClass('animation-flip-end');
        }
      });
    };
    const observer = new IntersectionObserver(callback, {});
    observer.observe(this);
  });
});