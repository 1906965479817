import $ from 'jquery';

$(() => {
  const $sections = $('[data-background-color]');
  $sections.each(function () {
    const callback = (entries) => {
      entries.forEach((entry) => {
        const $video = $(this).find('video');

        if (entry.isIntersecting) {
          $(this).addClass('fade-in');
          const className = $(this).data('background-color');
          $('body')
            .removeClass('black')
            .removeClass('white')
            .addClass(className)
            .addClass('transition');

          if ($video.length && $video.attr('autoplay') === true) {
            $video[0].play();
          }
        } else {
          $(this).removeClass('fade-in');
          if ($video.length && $video.attr('autoplay') === true) {
            $video[0].stop();
          }
        }
      });
    };
    const observer = new IntersectionObserver(callback, { threshold: 0.3 });
    observer.observe(this);
  });
});
