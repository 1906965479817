import $ from 'jquery';

$(() => {
  const $listItems = $('[data-animated-list-item]');
  $listItems.each(function () {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(this).removeClass('fade-out').addClass('fade-in');
        } else {
          $(this).removeClass('fade-in').addClass('fade-out');
        }
      });
    };
    const observer = new IntersectionObserver(callback, {});
    observer.observe(this);
  });
})