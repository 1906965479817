import $ from 'jquery';

$(() => {
  const $videoTrigger = $('.stage-video__headline em');
  const $video = $videoTrigger.parents('.stage-video').find('.stage-video__video video');

  let interval;
  let playbackSpeed = 1;

  $videoTrigger.on('mouseenter', () => {
    interval && clearInterval(interval);
    playbackSpeed = 1;
    $video[0].playbackRate = playbackSpeed;
    $video[0].play();
  });

  $videoTrigger.on('mouseleave', () => {
    interval = window.setInterval(() => {
      if(playbackSpeed <= 0) {
        $video[0].pause();
        $video[0].currentTime = 0;
        playbackSpeed = 1;
        clearInterval(interval);
        interval = 0;
      } else {
        playbackSpeed -= 0.1;
        $video[0].playbackRate = Math.abs(playbackSpeed.toFixed(2));
      }
    }, 50)
  });
});
